<template>
    <div class="page-box">
        <div class="header">
            <van-nav-bar
            :title="$t('mining.title')"
            left-arrow
            @click-left="$router.go(-1)"
            >
            </van-nav-bar>
        </div>
        <div class="maincontent">
            <van-nav-bar></van-nav-bar>
            <div style="display: flex;align-items: center;justify-content: space-between;padding: 1.34rem 0 1.34rem 0.94rem;background-color: #2351BD;">
                <div>
                    <div style="font-size: 0.75rem;color: #83AEDD;margin-bottom: 0.69rem;">{{$t('mining.funds')}}</div>
                    <div style="color: #FFFFFF;font-weight: bold;">
                        <span style="font-size: 1.25rem;">{{userdata.total}}</span>
                        <span style="font-size: 0.81rem;padding-left: 0.34rem;">USD</span>
                    </div>
                </div>
                <div @click="$router.push('/subscribeList')" style="display: flex;align-items: center;background: #406BC6;border-radius: 2.81rem 0rem 0rem 2.81rem;padding: 0.56rem 0.81rem 0.56rem 0.63rem;">
                    <van-image width="0.69rem" height="0.75rem" :src="require('../../assets/img/locked-order-icon.png')" />
                    <div style="font-size: 0.81rem;color: #FFFFFF;margin-left: 0.47rem;">{{$t('mining.locked-order')}}</div>
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;background-color: #406BC6;padding: 1.09rem 0;">
                <div style="flex: 1;">
                    <div style="font-size: 0.94rem;font-weight: bold;color: #FFFFFF;margin-bottom: 0.69rem;">{{userdata.today_waiting}}</div>
                    <div style="font-size: 0.69rem;color: #91AEDF;display: flex;align-items: center;">
                        <div style="flex: 1;padding-right: 0.2rem;">{{$t('mining.expected-earnings-today')}}</div>
                        <van-image @click="show = true" width="0.75rem" height="0.75rem" :src="require('../../assets/img/locked-order-issue.png')" />
                    </div>
                </div>
                <div style="flex: 1;">
                    <div style="font-size: 0.94rem;font-weight: bold;color: #FFFFFF;margin-bottom: 0.69rem;">{{userdata.total_income}}</div>
                    <div style="font-size: 0.69rem;color: #91AEDF;">{{$t('mining.cumulative-income')}}</div>
                </div>
                <div style="flex: 1;">
                    <div style="font-size: 0.94rem;font-weight: bold;color: #FFFFFF;margin-bottom: 0.69rem;">{{userdata.total_orders}}</div>
                    <div style="font-size: 0.69rem;color: #91AEDF;">{{$t('mining.order-in-escrow')}}</div>
                </div>
            </div>
            <div >
                <div class="prod-item" v-for="(item, key) in list" :key="key">
                    <div class="item-top">
                        <div class="title">USD(USDT){{$t('mining.lock-up')}}/{{item.times}}(day)</div>
                        <div class="lock-btn" @click="$router.push('/subscribemining?id='+item.id)">{{$t('mining.lock-up')}}</div>
                    </div>
                    <div class="row-flex">
                        <div>
                            <div class="t1">{{$t('mining.minimum-single')}}</div>
                            <div class="t2">{{item.low_money}}</div>
                        </div>
                        <div>
                            <div class="t1">{{$t('mining.single-most')}}</div>
                            <div class="t2">{{item.max_money=='-1'?$t('mining.unlimited'):item.max_money}}</div>
                        </div>
                        <div>
                            <div class="t1">{{$t('mining.daily-rate-of-return')}}</div>
                            <div class="t2">{{item.daily_rate_min}}-{{item.daily_rate_max}}%</div>
                        </div>
                        <div style="text-align: right;">
                            <div style="color: #8796A5;">{{$t('mining.lock-up-period')}}</div>
                            <div class="t2">{{item.times}}(day)</div>
                        </div>
                    </div>
                </div>
                <div style="font-size: 0.81rem;color: #4B8EFF;text-align: center;padding-top: 1.88rem;">{{$t('mining.no-more')}}</div>
            </div>
        </div>
        <van-overlay :show="show">
            <div class="wrapper">
                <div class="block">
                    <div class="block-title">{{$t('mining.explain')}}</div>
                    <div class="block-content">
                        {{$t('mining.explain1')}} <br/>
                        {{$t('mining.explain2')}} <br/>
                        {{$t('mining.explain3')}}
                    </div>
                    <div  @click="show = false" class="block-close">{{$t('mining.got-it')}}</div>
                </div>
            </div>
        </van-overlay>
    </div>
  </template>

<script>
export default {
  data() {
    return {
      list: [],
      userdata: {},
      show: false
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    //
    async getdata() {
      const { data } = await this.$http.get('/home/miner/infolist')
      this.list = data.data.list
      this.userdata = data.data.userdata
      console.log('data', this.list)
    }
  }
}
</script>

  <style lang="less" scoped>
  .page-box{
    min-height: 100vh;
  }
  .maincontent {
  }
  .prod-item{
    padding: 0.63rem 0.94rem;
    border-bottom: 0.06rem solid #EFF1F3;
    .item-top {
        display: flex;justify-content: space-between;align-items: center;margin-bottom: 1rem;
    }
    .title{
        font-size: 0.81rem;
        color: #333848;
    }
    .lock-btn{
        width: 5.06rem;
        height: 1.75rem;
        line-height: 1.75rem;
        text-align: center;
        background: #325DE3;
        border-radius: 0.19rem;
        color: #fff;
        font-size: 0.81rem;
    }
    .row-flex{
        display: flex;
        >div{
            flex: 1;
            text-align: left;
            font-size: 0.69rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .t1{
            color: #8796A5;
            max-width: 5rem;
        }
        .t2{
            color: #333848;
            margin-top: 0.75rem;
        }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 20rem;
    background: #FFFFFF;
    border-radius: 0.38rem;
    opacity: 1;
    padding: 1.25rem 1.16rem;
    box-sizing: border-box;
    .block-title{
        text-align: left;
        font-size: 1.13rem;
        color: #333333;
        margin-bottom: 0.72rem;
    }
    .block-content{
        font-size: 0.81rem;
        color: #333848;
        line-height: 1.19rem;
        border-bottom: 0.13rem solid #C0C0C0;
        padding-bottom: 0.94rem;
        text-align: left;
    }
    .block-close{
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        color: #fff;
        background: #325DE3;
        border-radius: 0.19rem;
        margin-top: 1.25rem;
    }
  }
</style>
